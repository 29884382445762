import { getEnvironment } from "../config/environment";

export interface TrackEventsTypes {
  action: string;
  category: string;
  label: string | null | undefined;
  value?: string | number | null | undefined | object;
}

const config = getEnvironment();
export const trackEvent = ({ label, category, action, value }: TrackEventsTypes): void => {
  const options = {
    eventCategory: category,
    eventAction: action,
    eventLabel: label ? label : "",
    eventValue: value ?? "",
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (typeof window !== "undefined" && typeof window.ga !== "undefined") {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window?.ga("send", "event", options);
  }
  if (config.GATSBY_GA_TRACKING_ID !== "disabled") {
    const options = {
      event_category: category,
      event_label: label ? label : "",
      event_value: value ?? "",
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (typeof window !== "undefined" && typeof window.gtag !== "undefined") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.gtag("event", action, options);
    }
  }
};

export const gtmSend = (event: unknown): void => {
  // Send GTM tag
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const data = window?.["dataLayer"];
  if (data) {
    //data can be null/undefined sometimes
    data.push(event);
  }
};
